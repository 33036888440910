import React from "react";
import "./footer.scss";
import logo from "../../Media/logo_jgc_white.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Jakarta garden City</h2>
            <h3>
              Main Boulevard Jl. Jkt Garden City Boulevard, RT.11/RW.8, Cakung
              Tim., Kec. Cakung, Kota Jakarta Timur, Daerah Khusus Ibukota
              Jakarta 13910
            </h3>
            <h4>Contact us : +6282258519207</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© Jakarta Garden City</div>
    </div>
  );
};

export default footer;
