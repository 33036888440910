import Vastu1 from '../../../Media/Residential/Vastu/vastu (1).webp'
import Vastu2 from '../../../Media/Residential/Vastu/vastu (2).webp'
import Vastu3 from '../../../Media/Residential/Vastu/vastu (3).webp'
import Vastu4 from '../../../Media/Residential/Vastu/vastu (4).webp'
import Vastu5 from '../../../Media/Residential/Vastu/vastu (5).webp'
import Vastu6 from '../../../Media/Residential/Vastu/vastu (6).webp'
import Vastu7 from '../../../Media/Residential/Vastu/vastu (7).webp'
import Vastu8 from '../../../Media/Residential/Vastu/vastu (8).webp'
import Vastu9 from '../../../Media/Residential/Vastu/vastu (9).webp'
import Vastu10 from '../../../Media/Residential/Vastu/vastu (10).webp'
import Vastu11 from '../../../Media/Residential/Vastu/vastu (11).webp'
import Vastu12 from '../../../Media/Residential/Vastu/vastu (12).webp'
import Vastu13 from '../../../Media/Residential/Vastu/vastu (13).webp'
import Vastu14 from '../../../Media/Residential/Vastu/vastu (14).webp'
import Vastu15 from '../../../Media/Residential/Vastu/vastu (15).webp'
import Vastu16 from '../../../Media/Residential/Vastu/vastu (16).webp'
import Vastu17 from '../../../Media/Residential/Vastu/vastu (17).webp'


const Vastuimages =[ 
    Vastu1,Vastu2,Vastu3,Vastu4,Vastu5,Vastu6,Vastu7,Vastu8,Vastu9,Vastu10,Vastu12,Vastu13,Vastu14,Vastu15,Vastu16,Vastu17
]

export default Vastuimages;