import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Juraimages from "./Jura";
import Maisonimages from "./Maison";
import Vastuimages from "./Vastu";
import Wisteriaimages from "./Wisteria";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Lemonadewa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282258519207&text=Halo+Kenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Maison)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Gardenwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282258519207&text=Halo+Kenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Gran%20Jura)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282258519207&text=Halo+Kenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Wisteria)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const WTwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282258519207&text=Halo+Kenny%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20(Vastu)%20https://www.jgc-jakartagardencity.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Maisonimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Maison</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Free Smarthome System</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 84m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 103m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 4</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Lemonadewa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Juraimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Grand Jura</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 112m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 116m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Gardenwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Wisteriaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Wisteria</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Siap Huni</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">13</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 112m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 116m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cherrywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vastuimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Vastu</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="white" icon={faMedal} />
              <div className="namalokasi">Tanpa DP</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">15</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="white" icon={faArrowsUpDownLeftRight} />
            <span> 135m²</span>
            <FontAwesomeIcon color="white" icon={faHouse} />
            <span> 250m²</span>
            <FontAwesomeIcon color="white" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="white" icon={faShower} />
            <span> 3+1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={WTwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
