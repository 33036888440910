import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logosumban from "../../Media/logo_jgc.png";

const aboutsumban = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="about" className="about-sumban">
      <div className="containerlogo">
        <div className="logosumban">
          <img className="gambarsumban" src={logosumban} alt="logo sumban" />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>Building Homes and People with Heart</h1>
        </div>
        <div className="desk">
          Jakarta Garden City adalah kawasan permukiman terintegrasi seluas 370
          hektar di Cakung, Jakarta Timur. PT Mitra Sindo Sukses, anak
          perusahaan PT Modernland Realty Tbk, bertanggung jawab atas
          pengembangan proyek ini. Dengan 7.000 unit perumahan, area ritel dan
          hiburan, sekolah internasional, rumah sakit, gedung perkantoran, pusat
          perbelanjaan, dan fasilitas umum lainnya, Jakarta Garden City
          menawarkan konsep rumah yang unik dan berkualitas tinggi. Kawasan
          Jakarta Garden City terdiri dari tiga area utama: Garden City, River
          Garden, dan Lake Garden. Pusat perbelanjaan AEON MALL, yang merupakan
          bagian dari pengembangan, adalah ciri khasnya.
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
