import Surrounding1 from '../../../src/Media/surrounding/Surrounding (1).webp'
import Surrounding2 from '../../../src/Media/surrounding/Surrounding (2).webp'
import Surrounding3 from '../../../src/Media/surrounding/Surrounding (3).webp'
import Surrounding4 from '../../../src/Media/surrounding/Surrounding (4).webp'
import Surrounding5 from '../../../src/Media/surrounding/Surrounding (5).webp'
import Surrounding6 from '../../../src/Media/surrounding/Surrounding (6).webp'





const Surroundingimages =[
    Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6
]

export default Surroundingimages;