import Saphirre1 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (1).webp'
import Saphirre2 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (2).webp'
import Saphirre3 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (3).webp'
import Saphirre4 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (4).webp'
import Saphirre5 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (5).webp'
import Saphirre6 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (6).webp'
import Saphirre7 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (7).webp'
import Saphirre8 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (8).webp'
import Saphirre9 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (9).webp'
import Saphirre10 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (10).webp'
import Saphirre11 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (11).webp'
import Saphirre12 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (12).webp'
import Saphirre13 from '../../../Media/Residential/New Mahakam/Saphirre/Saphirre (13).webp'


const Saphirreimages =[
Saphirre1,Saphirre2,Saphirre3,Saphirre4,Saphirre5,Saphirre6,Saphirre7,Saphirre8,Saphirre9,Saphirre10,Saphirre11,Saphirre12,Saphirre13
]

export default Saphirreimages;