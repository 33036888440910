import Jura1 from '../../../Media/Residential/Jura/Jura (1).webp'
import Jura2 from '../../../Media/Residential/Jura/Jura (2).webp'
import Jura3 from '../../../Media/Residential/Jura/Jura (3).webp'
import Jura4 from '../../../Media/Residential/Jura/Jura (4).webp'
import Jura5 from '../../../Media/Residential/Jura/Jura (5).webp'
import Jura6 from '../../../Media/Residential/Jura/Jura (6).webp'
import Jura7 from '../../../Media/Residential/Jura/Jura (7).webp'
import Jura8 from '../../../Media/Residential/Jura/Jura (8).webp'
import Jura9 from '../../../Media/Residential/Jura/Jura (9).webp'
import Jura10 from '../../../Media/Residential/Jura/Jura (10).webp'
import Jura11 from '../../../Media/Residential/Jura/Jura (11).webp'
import Jura12 from '../../../Media/Residential/Jura/Jura (12).webp'
import Jura13 from '../../../Media/Residential/Jura/Jura (13).webp'
import Jura14 from '../../../Media/Residential/Jura/Jura (14).webp'
import Jura15 from '../../../Media/Residential/Jura/Jura (15).webp'
import Jura16 from '../../../Media/Residential/Jura/Jura (16).webp'
import Jura17 from '../../../Media/Residential/Jura/Jura (17).webp'

const Juraimages =[
Jura1,Jura2,Jura3,Jura4,Jura5,Jura6,Jura7,Jura8,Jura9,Jura10,Jura11,Jura12,Jura13,Jura14,Jura15,Jura16,Jura17,
]

export default Juraimages;