import Topaz1 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (1).webp'
import Topaz2 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (2).webp'
import Topaz3 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (3).webp'
import Topaz4 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (4).webp'
import Topaz5 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (5).webp'
import Topaz6 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (6).webp'
import Topaz7 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (7).webp'
import Topaz8 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (8).webp'
import Topaz9 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (9).webp'
import Topaz10 from '../../../Media/Residential/New Mahakam/Topaz/Topaz (10).webp'

const Topazimages =[
Topaz1,Topaz2,Topaz3,Topaz4,Topaz5,Topaz6,Topaz7,Topaz8,Topaz9,Topaz10
]

export default Topazimages;