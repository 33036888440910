import Maison1 from '../../../Media/Residential/Maison/Maison (1).webp'
import Maison2 from '../../../Media/Residential/Maison/Maison (2).webp'
import Maison3 from '../../../Media/Residential/Maison/Maison (3).webp'
import Maison4 from '../../../Media/Residential/Maison/Maison (4).webp'
import Maison5 from '../../../Media/Residential/Maison/Maison (5).webp'
import Maison6 from '../../../Media/Residential/Maison/Maison (6).webp'
import Maison7 from '../../../Media/Residential/Maison/Maison (7).webp'
import Maison8 from '../../../Media/Residential/Maison/Maison (8).webp'
import Maison9 from '../../../Media/Residential/Maison/Maison (9).webp'

const Maisonimages =[
Maison1,Maison2,Maison3,Maison4,Maison5,Maison6,Maison7,Maison8,Maison9
]

export default Maisonimages;